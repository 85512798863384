import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import CenterWrap from '../components/center-wrap';
import Layout from '../components/layout';
import Posts from '../components/posts';
import postShape from '../shapes/post';

const Index = ({
  data: {
    allMarkdownRemark: { edges: posts }
  }
}) => (
  <Layout>
    <CenterWrap>
      <h2>Blog</h2>
      <Posts posts={posts.map(post => post.node)} />
    </CenterWrap>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(postShape)
    })
  }).isRequired
};

export default Index;

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
          }
        }
      }
    }
  }
`;
